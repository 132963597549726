import React, { useEffect, useRef } from 'react'
import ReadMoreComment from '../../../components/ReadMoreComment'
import { CommentFormatter } from '../../../utils'

const SpotlightComment = ({ comment, modalOpen, setModalOpen , i }) => {

    const handleClick = (text) => {
        setModalOpen(!modalOpen)
    }
    return (
        comment?.comment?.length > 150 ?
            <ReadMoreComment comment={comment} handleClick={handleClick} setModalOpen={setModalOpen} modalOpen={modalOpen} />
            :
            <p className={'text post-description-main'}  ><CommentFormatter comment={comment} handleClick={handleClick} /></p>
    )
}

export default SpotlightComment