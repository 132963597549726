// /v1/user/getSharePostDetails

export const getSharePostDetails = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/user/getSharePostDetails", payload)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};

export const getShareCommunityGroupDetail = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/user/getShareCommunityGroupDetail", payload)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};
export const getShareGroupPostUsingGroupId = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/user/getShareGroupPostUsingGroupId", payload)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};

export const getShareOrgDetails = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/user/getShareOrgDetails", payload)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};

export const getShareEventDetails = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/user/getShareEventDetails", payload)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};

export const resetPasswordData = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/user/resetPassword", payload)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });

};

export const getPhysicianDetailsData = (payload) => async (dispatch, getState, api) => {
    return await api.post('/admin/getPhysicianDetails',payload).then((res) => {
        if(res.data.success){
            dispatch({
                type: 'PHYSICIAN_DETAILS_PROFILE',
                payload: res.data.data.physicianProfile,
            })
        }
        return res;
    }).catch((err) => {
        return err.response;
    });
}
///v1/user/getUserProfile
export const getPhysicianDetailsProfile = (payload) => async (dispatch, getState, api) => {
    return await api.post('/user/getUserProfile',payload).then((res) => {
        if(res?.data?.success){
            dispatch({
                type: 'PHYSICIAN_NEW_PROFILE',
                payload: res.data.data.physicianProfile,
            })
        }
        return res;
    }).catch((err) => {
        return err.response;
    });
}
