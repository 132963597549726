import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children}) =>{

    return(
        <>
            {
                (window.location.pathname !== '/reset-password' && window.location.pathname !== '/reset-password-success') &&
                <Header/>
            }

                {children}
            {
               ( window.location.pathname !== '/reset-password' && window.location.pathname !== '/reset-password-success' ) &&
                <Footer/>
            }

        </>
    )
}
export default Layout