import React from "react";
import moment from "moment";
import Logo from "../../assets/images/cg_footer_logo.svg";

const Footer = () => {
    return(
        <footer className="resetpsw_footer website-footer-details">
            <div className={'can_container'}>
                <div className="resetpsw_footer_main">
                    <div className="footer_logo">
                        <img src={Logo} alt="logo" />
                        <span>A Haploscope Company</span>
                    </div>
                    <p className="copyright_txt">Copyright © {`${moment().format('YYYY')}`} Haploscope - All Rights Reserved</p>
                </div>
            </div>
        </footer>
    )
}
export default Footer