const initialState = {
    phyDetails:{},
    phyNewDetails:{}
}

export default function postReducer(state = initialState, { type, payload }) {
    switch (type) {
        case 'PHYSICIAN_DETAILS_PROFILE' : {
            return {
                ...state,
                phyDetails:payload
            }
        }
        case 'PHYSICIAN_NEW_PROFILE' : {
            return {
                ...state,
                phyNewDetails:payload
            }
        }
        default:
            return state;
    }
}