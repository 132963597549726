import React from "react";


const ErrorResetPopup = ({modalAllVal,handleOpenModal})=>{

    const {body,auth}= modalAllVal;
    return(
        <div className="delete-pop">
            <div className="title text_capitalize">
                Something went wrong!
            </div>
            {
                auth === 'LinkExpired' &&
                    <div className="desc">
                        It appears that this link to reset your
                        password has expired. Please tap on
                        <span> Forgot Password</span> in the app again
                        to proceed.
                    </div>
            }
            {
                auth === 'InvalidToken' &&
                    <div className="desc">
                        We are unable to process your
                        password reset request at this moment.
                        Please tap on <span> Forgot Password</span> in the
                        app again to proceed.
                    </div>
            }
            {
                auth === 'SamePassword' &&
                <div className="desc text-color">
                    {body}
                </div>
            }
            <div>
                <button className="btn btn_primary" onClick={()=>handleOpenModal()} > Ok</button>
            </div>
        </div>
    )
}
export default ErrorResetPopup