import React from "react";


const CommonPop =({modalAllVal,handleOpenModal})=>{
    const {header,body,auth}= modalAllVal;

    return(
        <div className="delete-pop">
            <div className="title text_capitalize">
                {header}
            </div>
            <div className="desc">
                {body}
            </div>
            <div>
                {
                    auth ?
                        <button className="btn btn_primary  mt_2" onClick={()=>handleOpenModal()} > Ok</button>
                        : <button className="btn btn_primary  mt_2" onClick={()=>window.open('https://cancergo.org/',"_self")} >Ok</button>
                }
            </div>
        </div>
    )
}
export default CommonPop