import moment from "moment";

export const generateAvatar = (text) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Draw background
    context.fillStyle = stringToHslColor(text);
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Draw text
    context.font = "bold 65px SF_Pro_Display_Regular";
    context.fillStyle = "#fff";
    context.textAlign = "center";
    context.textBaseline = "middle";

    let first = text?.split(' ')[0]?.charAt(0)?.toUpperCase();
    let last = text?.split(' ')[1]?.charAt(0)?.toUpperCase();

    if (!last) {
        last = text?.split(' ')[0]?.charAt(1)?.toUpperCase() || text?.split(' ')[0]?.charAt(0)?.toUpperCase();
    }
    if (!first) {
        first = "S";
        last = "U";
    }

    context.fillText(first + last, canvas.width / 2, canvas.height / 2);

    return canvas.toDataURL("image/png");
};

export const getDuration = (duration, date) => {
    let seconds = duration.asSeconds();
    let minutes = duration.asMinutes();
    let hours = duration.asHours();
    let days = duration.asDays();
    let months = duration.asMonths();
    let years = duration.asYears();

    if (minutes >= 525600) {
        return `${years.toFixed()} year`
    } else if (months >= 1) {
        return moment(date).format('MMM DD')
    } else if (days < 2) {
        if (seconds < 60) {
            return "now"
        } else if (minutes <= 1) {
            return `${minutes?.toString()?.split('.')[0]} min`
        } else if (minutes > 1 && minutes < 60) {
            return `${minutes?.toString()?.split('.')[0]} mins`
        } else if (minutes >= 60 && minutes < 120) {
            return `${hours?.toString()?.split('.')[0]} hour`
        } else if (minutes >= 120 && minutes < 1440) {
            return `${hours?.toString()?.split('.')[0]} hours`
        } else {
            return `${days?.toString()?.split('.')[0]} day`
        }
    } else if (days > 1) {
        return `${days?.toString()?.split('.')[0]} days`
    }

}

export const stringToHslColor = (str, s = 30, l = 80) => {
    let hash = 0;
    for (let i = 0; i < str?.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
};


export const dotGenerator = (text) => {
    let comment = text;
    let result = "";
    comment = comment?.replaceAll('\n\n', ' ');
    comment = comment?.replaceAll('\n', ' ');
    let splitText = comment?.trim().split(' ');
    if (splitText?.length > 30) {
        result = <span>{`${splitText?.slice(0, 30).join(' ')}...`}
        </span>
    }
    else {
        result = `${splitText?.slice(0, splitText?.length).join(' ')}`
    }
    return (result || "")
};

export const updateMetaTags = (data) => {

    const {
        title = 'cancerGO | The Global Cancer Community',
        url = window.location.href,
        desc = "cancerGO | The Global Cancer Community",
        image = 'https://d3m33pazxh9zxf.cloudfront.net/logo/1664951298739-FB-Meta.png',
        // desc = "Grow your cancerGo and advance your career with cancerGo",
    } = data;

    document
        .querySelector(`meta[name=description]`)
        .setAttribute("content", desc);
    document
        .querySelector(`meta[name=title]`)
        .setAttribute("content", title);

    document
        .querySelector(`meta[property="og:url"]`)
        ?.setAttribute("content", url);
    document
        .querySelector(`meta[property="og:title"]`)
        .setAttribute("content", `${title} | cancerGO`);
    document
        .querySelector(`meta[property="og:description"]`)
        .setAttribute("content", desc);
    document
        .querySelector(`meta[property="og:image"]`)
        .setAttribute("content", image);


    document
        .querySelector(`meta[name="twitter:url"]`)
        .setAttribute("content", url);
    document
        .querySelector(`meta[name="twitter:title"]`)
        .setAttribute("content", `${title} | cancerGO`);
    document
        .querySelector(`meta[name="twitter:description"]`)
        .setAttribute("content", desc);
    document
        .querySelector(`meta[name="twitter:image"]`)
        .setAttribute("content", image);
};


export const getMetaContent = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    const meta = [...div.getElementsByTagName('meta')];

    let metaData = {};
    meta?.map((data) => {
        if (data.getAttribute('property')) {
            metaData = { ...metaData, [data.getAttribute('property')]: data.getAttribute('property') }
        } else {
            return null;
        }
    });
    return metaData
};


export function urlify(text) {
    let urlRegex = /(https?:\/\/[^\s]+)/g;
    return text?.match(urlRegex)?.[0];
}

export function CommentFormatter({ comment, handleClick }) {
    let commentArray = [];
    let people = comment?.tagPeople?.reduce((acc, cur) => [...acc, cur.slice(cur.indexOf('|') + 1, cur.lastIndexOf('|'))], [])
    let hashtags = comment?.hashtag?.reduce((acc, cur) => [...acc, cur.slice(cur.indexOf('|') + 1, cur.lastIndexOf('>'))], [])
    let commentUrl = comment.comment.split(' ').filter((e) => {
        return e.toLowerCase().includes('https:') || e.toLowerCase().includes('http:') || e.toLowerCase().includes('www.')
    })
    let merged = [...(new Set([...people, ...hashtags, ...commentUrl])).values()];
    let tempStr = (Math.random(800000) * 100).toFixed(0);


    let res = merged.reduce((acc, cur) => {
        return acc.replaceAll(cur, `<${tempStr}>${cur}<${tempStr}>`)
    }, comment.comment)

    res.split(`<${tempStr}>`).forEach(item => {
        let index = merged.findIndex(val => val === item);
        if (index > -1) {
            commentArray.push(<span onClick={() => handleClick(item)} className="blue-color">{item}</span>)
        } else {
            commentArray.push(item)
        }
    })

    return commentArray
}

export function CommentFormatterDescription({ comment, handleClick }) {
    let commentArray = [];
    let people = comment?.tagPeople?.reduce((acc, cur) => [...acc, cur.slice(cur.indexOf('|') + 1, cur.lastIndexOf('|'))], [])
    let hashtags = comment?.hashtag?.reduce((acc, cur) => [...acc, cur.slice(cur.indexOf('|') + 1, cur.lastIndexOf('>'))], [])
    let commentUrl = comment.description.split(' ').filter((e) => {
        return e.toLowerCase().includes('https:') || e.toLowerCase().includes('http:') || e.toLowerCase().includes('www.')
    })
    let merged = [...(new Set([...people, ...hashtags, ...commentUrl])).values()];
    let tempStr = (Math.random(800000) * 100).toFixed(0);


    let res = merged.reduce((acc, cur) => {
        return acc.replaceAll(cur, `<${tempStr}>${cur}<${tempStr}>`)
    }, comment.description)

    res.split(`<${tempStr}>`).forEach(item => {
        let index = merged.findIndex(val => val === item);
        if (index > -1) {
            commentArray.push(<span onClick={() => handleClick(item)} className="blue-color">{item}</span>)
        } else {
            commentArray.push(item)
        }
    })

    return commentArray
}