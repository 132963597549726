import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import manage_group_plus_icon from "../../../src/assets/images/manage_group_plus_icon.svg";
import manage_group_slider_img from "../../../src/assets/images/manage_group_slider_img.png";
import heart_icon_img from "../../assets/images/heart_icon_img.svg";
import comment_icon_img from "../../assets/images/comment_icon_img.svg";
import public_group_icon from "../../assets/images/manage_public_icon_img.svg";
import private_group_icon from "../../assets/images/manage_private_icon_img.svg";
import blue_comment_icon from "../../assets/images/blue_comment_icon.png";
import post_user_profile_img from "../../assets/images/person_six.png";
import side_icon from "../../assets/images/side_icon.png";
import cmt_grp_i from "../../assets/images/cmt_grp_i.png";
import Loader from "../../components/Loader";
import { useDispatch } from "react-redux";
import { getShareCommunityGroupDetail, getShareGroupPostUsingGroupId } from "../../redux/post/action";

import CustomModal from '../../components/CustomModal'
import PopComponent from "../../components/PopComponent";
import { CommentFormatterDescription, generateAvatar, getDuration, updateMetaTags } from "../../utils";
import { Helmet } from "react-helmet";
import moment from "moment";
import SpotlightComment from "../Post/SpotlightComment";
import heart_liked_img from "../../assets/images/heart_liked_img.svg";
import no_post_img from "../../../src/assets/images/no_post_img.png";

const CommunityGroup = () => {

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get('id');
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [rowData, setRowData] = useState({})
  const [postRowData, setPostRowData] = useState({})

  // const [pagination, setPagination] = useState({ rowsPerPage: 10, page: 1 });
  const [modalDetails, setModalDetails] = useState({ modalValue: '', modalName: '', modalIsOpen: false });
  let ModalData = PopComponent[modalDetails?.modalName];

  useEffect(() => {
    getGroupDetails()
    getGroupDetailPost()
  }, []);

  const getGroupDetails = () => {
    let payload = {
      id: id
    };
    setLoader(true);
    dispatch(getShareCommunityGroupDetail(payload)).then(res => {
      setLoader(false);
      if (res?.data?.success) {

        setRowData({
          ...rowData,
          gropDetails: res?.data?.data
        });
        updateMetaTags({
          title: res?.data?.data?.group_name, desc: res?.data?.data?.bio?.slice(0, 150),
          image: res?.data?.data?.coverImage
        });
      } else {
        handleOpenModal('commonPop', { header: "Info", body: res?.data?.message, auth: false });
      }
    })
  }

  const getGroupDetailPost = () => {
    let payload = {
      group_id: id
    };
    setLoader(true);
    dispatch(getShareGroupPostUsingGroupId(payload)).then(res => {
      setLoader(false);
      if (res?.data?.success) {
        setPostRowData({
          ...postRowData,
          postDetails: res?.data?.data?.docs
        });
      } else {
        handleOpenModal('commonPop', { header: "Info", body: res?.data?.message, auth: false });
      }
    })
  }


  const handleOpenModal = (type, data) => {
    switch (type) {
      case 'commonPop': {
        setModalDetails({ ...modalDetails, modalValue: data, modalName: type, modalIsOpen: true });
        break;
      }
      default: {
        setModalDetails({ ...modalDetails, modalIsOpen: false });
      }
    }
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return <div className={className} onClick={onClick} />;
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return <div className={className} onClick={onClick} />;
  }

  const settings = {
    dots: true,
    infinite: false,
    // fade:true,
    autoplay: false,
    autoplaySpeed: 2000,
    cssEase: "linear",
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={window.location.href} />
        <title>{rowData?.gropDetails?.group_name ? `${rowData?.gropDetails?.group_name} | cancerGO` : 'cancerGO | The Global Cancer Community'}</title>
      </Helmet>
      <main className={`${loader ? 'loader_main' : ''}`}>
        {
          loader ?
            <div className={'spin_loder'}>
              <Loader className="data_load" />
            </div>
            :
            <>
              {
                modalOpen &&
                <div className="cg_popup_main">
                  <div className="popup_can">
                    <p className="pop_para">Please open the <a href="#"><span
                      className="blue_img">cancer</span><span className="red_img">GO</span></a> app to engage with
                      this post. You may need to download, install, and register in the app first.</p>
                    <button className="pop_close" onClick={() => setModalOpen(!modalOpen)}>ok</button>
                  </div>
                </div>
              }

              {
                rowData?.gropDetails &&
                <div className="manage_group">
                  <div className="can_container mobile_container">
                    <div className="manage_group_banner">
                      <div className="manage_group_img_slider">
                        {
                          rowData?.gropDetails?.images?.length > 0
                            ? <Slider {...settings}>
                              {
                                rowData?.gropDetails?.images?.map((imgSliser, i) => {
                                  return (
                                    <div className="manage_group_img_slider_item" key={i}>
                                      <img
                                        src={imgSliser}
                                        alt="manage_group_slider_img"
                                      />
                                    </div>
                                  )
                                })
                              }
                            </Slider>
                            : <div className="manage_group_img_slider_item" >
                              <img src={rowData?.gropDetails?.coverImage} alt="manage_group_slider_img" />
                            </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="healing_group_desc">
                    <div className="healing_group_wrapper">
                      <div className="can_container">
                        <div className="healing_group_header">
                          {
                            rowData?.gropDetails?.group_name && <h4>{rowData?.gropDetails?.group_name}</h4>
                          }
                        </div>
                        <div className="private_group_content flex justify_content_between">
                          <div className="private_group_icon">
                            {
                              rowData?.gropDetails?.group_privacy === 'public'
                                ? <img src={public_group_icon} alt="private_group_lock_icon" className="c_pointer" onClick={() => setModalOpen(!modalOpen)} />
                                : <img src={private_group_icon} alt="private_group_lock_icon" className="c_pointer" onClick={() => setModalOpen(!modalOpen)} />
                            }
                          </div>
                          <div className="private_group_txt">
                            {
                              rowData?.gropDetails?.group_privacy && <small onClick={() => setModalOpen(!modalOpen)} className="c_pointer">{rowData?.gropDetails?.group_privacy} Group</small>
                            }
                          </div>
                        </div>
                        <label className="administrator_lbl">Administrator</label>
                        <div className="location_content">
                          <div className="flex">
                            <div className="user_profile_pic">
                              {
                                rowData?.gropDetails?.adminUser?.user_type?.[0]?.type === "org"
                                  ? <img src={rowData?.gropDetails?.adminUser?.profile ? rowData?.gropDetails?.adminUser?.profile : generateAvatar(rowData?.gropDetails?.adminUser?.org_name)} className="s_icon c_pointer" alt="user_profile_pic" onClick={() => setModalOpen(!modalOpen)}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;  // prevents looping
                                      currentTarget.src = "https://d3m33pazxh9zxf.cloudfront.net/logo/1664951298739-FB-Meta.png";
                                    }} />
                                  : <img src={rowData?.gropDetails?.adminUser?.profile ? rowData?.gropDetails?.adminUser?.profile : generateAvatar(rowData?.gropDetails?.adminUser?.first_name + ' ' + rowData?.gropDetails?.adminUser?.last_name)} className="s_icon c_pointer" alt="user_profile_pic" onClick={() => setModalOpen(!modalOpen)}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;  // prevents looping
                                      currentTarget.src = "https://d3m33pazxh9zxf.cloudfront.net/logo/1664951298739-FB-Meta.png";
                                    }} />
                              }
                            </div>
                            <div className="users_location">
                              {
                                rowData?.gropDetails?.adminUser?.user_type?.[0]?.type === "org" ? <span>{rowData?.gropDetails?.adminUser?.org_name}</span> : <span>{rowData?.gropDetails?.adminUser?.first_name + ' ' + rowData?.gropDetails?.adminUser?.last_name}</span>
                              }
                            </div>
                          </div>

                        </div>
                        {
                          rowData?.gropDetails?.group_privacy === 'public' &&
                          <div className="view_group_info">
                            <small onClick={() => setModalOpen(!modalOpen)}>View Group Info & Rules of the group</small>
                          </div>
                        }
                        {
                          rowData?.gropDetails?.members?.length > 0 &&
                          <>
                            <div className="total_member">
                              <span>Members</span>
                              <p>
                                {
                                  rowData?.gropDetails?.members?.length > 1
                                    ? rowData?.gropDetails?.members?.[0]?.user_type?.[0]?.type === 'org'
                                      ? rowData?.gropDetails?.members?.[0]?.org_name + '& others have joined' : rowData?.gropDetails?.members?.[0]?.first_name + ' ' + rowData?.gropDetails?.members?.[0]?.last_name + ' & others have joined'
                                    : rowData?.gropDetails?.members?.[0]?.user_type?.[0]?.type === 'org'
                                      ? rowData?.gropDetails?.members?.[0]?.org_name + ' have joined' : rowData?.gropDetails?.members?.[0]?.first_name + ' ' + rowData?.gropDetails?.members?.[0]?.last_name + ' have joined'
                                }
                              </p>
                            </div>
                            <div className="like_by">
                              <div className="like_grp flex">
                                {
                                  rowData?.gropDetails?.members?.slice(0, 5)?.map((member, i) => {
                                    return (
                                      <img key={i}
                                        src={member?.profile ? member?.profile : generateAvatar(member?.org_name ? member?.org_name : member?.first_name + " " + member?.last_name)}
                                        alt="user_profile_img2"
                                        className="small_i"
                                        onClick={() => setModalOpen(!modalOpen)}
                                      />
                                    )
                                  })
                                }
                                {rowData?.gropDetails?.members?.length > 5 && <img src={cmt_grp_i} alt="like_user" className="small_i" onClick={() => setModalOpen(!modalOpen)} />}

                                {/* <img src={
                                  like?.user_type[0]?.type === "org" ?
                                      like?.profile ? like?.profile : generateAvatar(like?.org_name)
                                      :
                                      like?.profile ? like?.profile : generateAvatar(like?.first_name + ' ' + like?.last_name)
                              } alt="like_user" className="small_i" onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = "https://d3m33pazxh9zxf.cloudfront.net/logo/1664951298739-FB-Meta.png";
                              }} /> */}
                              </div>
                            </div>
                          </>
                        }


                        <div className={rowData?.gropDetails?.group_privacy === 'public' ? "join_grp" : "join_grp request_join_group"}>
                          <h5>
                            Join to follow, share, connect with other members, and get
                            notified about events and activities in the group{" "}
                          </h5>
                          <div className="join_btn">
                            <button className="view_more_btn" onClick={() => setModalOpen(!modalOpen)}>{rowData?.gropDetails?.group_privacy === 'public' ? "Join The Group" : "Request to Join"}</button>
                          </div>
                          <p>
                            By joining this group you are agreeing to the{" "}
                            <span> Rules Of The Group </span> from the Administrator
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {
                    rowData?.gropDetails?.group_privacy === 'public' &&
                    <div className="manage_group_footer_container">
                      <div className="manage_group_footer"></div>
                    </div>
                  }
                  {/* <div className="write_something_post">
                    <div className="can_container">
                      <div className="flex">
                        <div className="manage_group_plus_btn">
                          <button onClick={() => setModalOpen(!modalOpen)}>
                            <img
                              src={manage_group_plus_icon}
                              alt="manage_group_plus_icon"
                            />
                          </button>
                        </div>
                        <div className="input_for_write_post c_pointer">
                          <span onClick={() => setModalOpen(!modalOpen)}>Write something in this group</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="manage_group_footer_container">
                    <div className="write_something_post_footer"></div>
                  </div> */}

                  {
                    rowData?.gropDetails?.group_privacy === 'public' &&
              
                  <div className="can_container">
                    {
                      console.log('---->', postRowData?.postDetails)
                    }
                    {postRowData?.postDetails && postRowData?.postDetails.map((item, i) => {

                      let latestComment = item.latestComment
                      let x = moment()
                      let y = moment(item.createdAt)
                      let duration = moment.duration(x.diff(y))
                      let yc = moment(latestComment?.createdAt)
                      let cmntDuration = moment.duration(x.diff(yc))

                      if (i === 0) {
                        return (
                          <div className="showmore_article first_article">
                            <div className="main_postarticle_wrapper">
                              <div className="f_detail cg_add_line ">
                                <div className="cg_post_profile cg_post_profile_detail mb_12">
                                  <img
                                    src={item.users.profile ? item.users.profile : generateAvatar(item.users.org_name ? item.users.org_name : item.users.first_name + ' ' + item.users.last_name)}
                                    alt="user_profile_pic"
                                    className="i_degn img_profile c_pointer"
                                    onClick={() => setModalOpen(!modalOpen)}
                                  />
                                  <div className="cg_postUser_detail ">
                                    <h4 className="can_head c_pointer" onClick={() => setModalOpen(!modalOpen)}>
                                      {item.title}
                                    </h4>
                                    <p className="para_head c_pointer" onClick={() => setModalOpen(!modalOpen)}>{item.users.user_type[0].isDoctor ? 'Dr.' : ''} {item.users.org_name ? item.users.org_name : item.users.first_name + ' ' + item.users.last_name}</p>
                                    <div className="time_d time_dropdown_icon">
                                      <span>{getDuration(duration, item.createdAt)}</span>
                                      <img src={side_icon} alt="side_icon c_pointer" onClick={() => setModalOpen(!modalOpen)} />
                                    </div>
                                  </div>
                                </div>
                                <div className="side_detail">
                                  {
                                    item.titleImage !== '' ?
                                      <div className="title_img_wrapper c_pointer">
                                        <img src={item.titleImage} alt='title image' onClick={() => setModalOpen(!modalOpen)} onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src = "https://d3m33pazxh9zxf.cloudfront.net/logo/1664951298739-FB-Meta.png";
                                        }} />
                                      </div>
                                      : ''
                                  }
                                  <p className="para_detail post_desc">
                                    <CommentFormatterDescription comment={{ ...item, description: item?.description?.length > 300 ? item?.description.slice(0, 300) : item?.description }} handleClick={() => setModalOpen(!modalOpen)} />
                                    {item?.description?.length > 300 ? <span onClick={() => setModalOpen(!modalOpen)} className="readMore">...more</span> : ''}
                                  </p>

                                  {
                                    item?.images?.length > 0 &&
                                    <img className="mar_b cg_post_slider post_slider_img c_pointer" width={"100%"} src={item?.images?.[0]} alt="patient_img" onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = "https://d3m33pazxh9zxf.cloudfront.net/logo/1664951298739-FB-Meta.png";
                                    }} onClick={() => setModalOpen(!modalOpen)} />
                                  }

                                  {
                                    Object.keys(item?.metadata).length > 0 &&
                                    <div className={'title_img_wrapper_meta_main c_pointer'} onClick={() => setModalOpen(!modalOpen)}>
                                      <div className="title_img_wrapper_meta" >
                                        <img src={item?.metadata?.image} alt='title image' onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src = "https://d3m33pazxh9zxf.cloudfront.net/logo/1664951298739-FB-Meta.png";
                                        }} />
                                      </div>
                                      <p className={'meta_title'}>{item?.metadata?.title}</p>
                                    </div>
                                  }

                                  <div className="icon_content flex like_follow_btn like_follow_btn_padding">
                                    <div className="like_content flex like_comment_width">
                                      <img src={heart_icon_img} alt="Heart_icon" onClick={() => setModalOpen(!modalOpen)} />
                                      <img src={comment_icon_img} alt="comment_icon" onClick={() => setModalOpen(!modalOpen)} />
                                    </div>
                                    {/* <div className="learn_follow_btn">
                                      <button className="learn_btn">Learn more</button>
                                      <button className="Follow_btn">Follow</button>
                                    </div> */}
                                  </div>
                                  <div className="comment_box">
                                    {
                                      item?.likes === 0
                                        ? ''
                                        : item?.likes === 1
                                          ? <small className="comt_count cmnt_cnt_text">{item?.likes} Like</small>
                                          : <small className="comt_count cmnt_cnt_text">{item?.likes} Likes</small>
                                    }
                                    {
                                      item?.comments === 0
                                        ? ''
                                        : item?.comments === 1
                                          ? <span>{item?.comments} Comment</span>
                                          : <span>{item?.comments} Comments</span>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                            {item?.comments > 0 &&
                              <div className="f_detail cg_add_line main_comments_wrapper ">
                                <div className={item?.comments > 1 ? "cg_post_profile" : "cg_post_profile cg_post_profile_line"}>
                                  <div className="profile_line">
                                    <img
                                      src={latestComment?.commentBy?.profile ? latestComment?.commentBy?.profile : generateAvatar(latestComment?.commentBy?.org_name ? latestComment?.commentBy?.org_name : latestComment?.commentBy?.first_name + ' ' + latestComment?.commentBy?.last_name)}
                                      alt="user_profile_pic"
                                      className="s_icon c_pointer"
                                      onClick={() => setModalOpen(!modalOpen)}
                                    />
                                  </div>
                                  <div className="side_detail inner_comment_side_details">
                                    <div className="cg_postUser_detail">
                                      <p className="para_head c_pointer" onClick={() => setModalOpen(!modalOpen)}>{latestComment?.commentBy?.user_type[0].isDoctor ? 'Dr.' : ''} {latestComment?.commentBy?.org_name ? latestComment?.commentBy?.org_name : latestComment?.commentBy?.first_name + ' ' + latestComment?.commentBy?.last_name}</p>
                                      <div className="time_d">
                                        <span>{getDuration(cmntDuration, latestComment?.createdAt)}</span>
                                        <img src={side_icon} alt="side_icon" className="c_pointer" onClick={() => setModalOpen(!modalOpen)} />
                                      </div>
                                    </div>
                                    <p className="para_detail">
                                      <SpotlightComment comment={latestComment} modalOpen={modalOpen} setModalOpen={setModalOpen} />
                                    </p>
                                    {
                                      latestComment?.metadata &&
                                      <div className={'title_img_wrapper_meta_main c_pointer'} onClick={() => setModalOpen(!modalOpen)}>
                                        <div className="title_img_wrapper_meta">
                                          <img src={latestComment?.metadata?.image} alt='title image' onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = "https://d3m33pazxh9zxf.cloudfront.net/logo/1664951298739-FB-Meta.png";
                                          }} />
                                        </div>
                                        <p className={'meta_title'}>{latestComment?.metadata?.title}</p>
                                      </div>
                                    }

                                    {
                                      latestComment?.images?.length > 0 &&
                                      <img className="mar_b cg_post_slider c_pointer" width={"100%"} onClick={() => setModalOpen(!modalOpen)} src={latestComment?.images?.[0]} alt="patient_img" onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = "https://d3m33pazxh9zxf.cloudfront.net/logo/1664951298739-FB-Meta.png";
                                      }} />
                                    }
                                    <div className="icon_content flex">
                                      {
                                        latestComment?.commentLikes === 0
                                          ? <img src={heart_icon_img} alt="Heart_icon" onClick={() => setModalOpen(!modalOpen)} />
                                          : <img src={heart_liked_img} alt="Heart_icon" onClick={() => setModalOpen(!modalOpen)} />
                                      }
                                    </div>
                                    <div className="comment_box comment_like_reply">
                                      {
                                        latestComment?.commentLikes === 0
                                          ? ''
                                          : latestComment?.commentLikes === 1
                                            ? <small className="comt_count cmnt_cnt_text">{latestComment?.commentLikes} Like</small>
                                            : <small className="comt_count cmnt_cnt_text">{latestComment?.commentLikes} Likes</small>
                                      }
                                      {/* <small className="comt_count cmnt_cnt_text">{comment?.commentBy?.commentLikes} Likes</small> */}
                                      <span className='c_pointer' onClick={() => setModalOpen(!modalOpen)}>Reply</span>
                                    </div>
                                  </div>
                                </div>
                                {item?.comments > 1 &&
                                  <div className="d_flex view_all_sec">
                                    <img src={blue_comment_icon} alt="comment_icon" onClick={() => setModalOpen(!modalOpen)} />
                                    <div className="btn_view_all view_all_comment">
                                      <button className="view_more_btn" onClick={() => setModalOpen(!modalOpen)}>
                                        View More
                                      </button>
                                    </div>
                                  </div>}
                              </div>}
                          </div>
                        )
                      }
                    })}
                  </div>
                  }
                  {
                    rowData?.gropDetails?.group_privacy === 'private' &&
              
                  <div className="private_group_post">
                    <div className="can_container">
                      <div className="private_grp_wrapper">
                        {
                          rowData?.gropDetails?.bio !== "" &&
                          <div className="private_about_group">
                            <h4>About this group</h4>
                            <p>{rowData?.gropDetails?.bio}</p>
                          </div>
                        }

                        {
                          rowData?.gropDetails?.group_category?.length > 0 &&
                          <div className="private_grp_categories">
                            <div className="private_grp_categories_content">
                              <h4>Categories (by hashtags)</h4>
                              <div className="private_grp_hashtag">
                                {
                                  rowData?.gropDetails?.group_category?.map((e) => {
                                    return <span>{e.slice(e.indexOf('|') + 1, e.lastIndexOf('>'))}</span>
                                  })
                                }
                              </div>
                            </div>
                          </div>
                        }

                        {
                          rowData?.gropDetails?.group_rules?.length > 0 &&
                          <div className="private_rules_grp">
                            <div className="private_rules_grp_content">
                              <h4>Rules of the Group</h4>
                              {
                                rowData?.gropDetails?.group_rules?.map((e) => {
                                  return (
                                    <div className="private_rules_grp_desc">
                                      <h5>{e.title}</h5>
                                      <p>{e.text}</p>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  }
                  {rowData?.gropDetails?.group_privacy === 'public' && postRowData?.postDetails?.length === 0 ?
                    <div className="no_post_content">
                      <div className="no_post_can_container mobile_container">
                        <div className="no_post_wrapper">
                          <div className="no_post_desc">
                            <h4>No posts found here yet</h4>
                            <p>Why not join the group and start with your post?</p>
                          </div>
                          <div className="no_post_img">
                            <img src={no_post_img} alt="no_post_img" />
                          </div>

                          <div className="no_post_bg_clr_container">
                             <div className="no_post_bg_clr">
                             </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    : ""}
                </div>
              }
            </>
        }
        <CustomModal className={`modal ${modalDetails?.modalName}`} modalIsOpen={modalDetails?.modalIsOpen} handleOpenModal={handleOpenModal}>
          <ModalData handleOpenModal={handleOpenModal} modalAllVal={modalDetails?.modalValue} />
        </CustomModal>
      </main>
    </>
  );
};

export default CommunityGroup;
