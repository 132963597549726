import React, { useState } from "react";
import { CommentFormatter } from "../../utils";

const ReadMoreComment = ({ comment, modalOpen, setModalOpen, handleClick }) => {
    const text = comment.comment;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    return (
        <p className={'text post-description-main'}>
            {isReadMore ? <CommentFormatter handleClick={handleClick} comment={{ ...comment, comment: text?.slice(0, 150) }} /> : <CommentFormatter handleClick={handleClick} comment={comment} />}
            <span onClick={() => setModalOpen(!modalOpen)} className="read-or-hide">
                <span className={'red_t red_text'}>...more</span>
            </span>
        </p>
    );
};

export default ReadMoreComment