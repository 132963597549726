import React, { useEffect } from "react";
import main_logo from "../../assets/images/main_logo.svg"
import CgTopHeaderLogo from "../../assets/images/cg_top_header_logo.webp";
import { useSelector} from "react-redux";

const Header = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
   const physician = useSelector(state => state?.postReducer?.phyNewDetails)
    const id = params.get('id');
    useEffect(()=> {
        if(isChech()){
            postSchemaForApp(window.location?.pathname?.replace('/',''))
        }
    },[])

    const isChech = () => {
        let isMobile;
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
                isMobile = true;
        } else {
            isMobile = false;
        }
        return isMobile
    }

    const postSchemaForApp = async(name) => {
        switch (name) {
            case 'event-details':{
                console.log('event')
                window.location = `cancergo://event?eventId=${id}`;
                break;
            }
            case 'org-details':{
                console.log('org')
                window.location = `cancergo://organization?orgId=${id}`;
                break;
            }
            case 'post-details':{
                window.location = `cancergo://postDetails?postId=${id}`;
                break;
            }
            case 'physician-details' : {
                window.location = `cancergo://specialist?id=${id}`
                break;
            }
            case 'profile-details' : {
                window.location = `cancergo://specialist?id=${id}`
                // if(physician?.user_type?.[0]?.type === 'normal'){
                //     console.log(`User`)
                //     window.location = `cancergo://normalUser?id=${id}`
                // }
                // if(physician?.user_type?.[0]?.type === 'physician'){
                //     console.log(`Phy`)
                //     window.location = `cancergo://specialist?id=${id}`
                // }
                break;
            }
            case 'group-details' : {
                window.location = `cancergo://group-details?id=${id}`
                break;
            }
            default:{

            }
        }
    };


    return(
        <header>
            <div className="cg_top_mobile_header">
                <div  className='cg_topheader_logo'>
                    <img src={CgTopHeaderLogo} alt={'cancerGO logo'} title={'cancerGO logo'}/>
                </div>

                <button className="cg_topheader_open" onClick={()=>postSchemaForApp(window.location?.pathname?.replace('/',''))}>open</button>
            </div>
            <div className="can_container">
                <div className="header_line flex">
                    <div className="mainlogo">
                        <img src={main_logo} alt="" className=" logo_img"/>
                    </div>
                    <h4 className="main_heading">A first-ever online social network for cancer patients and survivors</h4>
                </div>
            </div>
        </header>
    )
}
export default Header