import Layout from "./Layout";
import React, { lazy, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import CommunityGroup from "./pages/CommunityGroup";
const Post = lazy(() => import("./pages/Post"));
const OrgPost = lazy(() => import("./pages/OrgPost"));
const EventPost = lazy(() => import("./pages/EventPost"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const ResetSuccessScreen = lazy(() => import("./pages/ResetPassword/ResetSuccessScreen"));
const PhysicianDetails = lazy(() => import("./pages/PhysicianDetails"));
const PhysicianNewDetails = lazy(() => import("./pages/PhysicianNewDetails"));
const Landing = lazy(() => import("./pages/Landing"));

const App = () => {
  useEffect(() => {
    if (window?.location?.origin === 'https://devapp.cancergo.org' || window?.location?.origin === 'https://stagecms.cancergo.org') {
      let link = document.createElement('meta');
      link.setAttribute('name', 'robots');
      link.content = "noindex,nofollow";
      document.getElementsByTagName('head')[0].appendChild(link);
    }
  }, []);
  return (
    <>
      <Layout>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/post-details" element={<Post />} />
          <Route path="/org-details" element={<OrgPost />} />
          <Route path="/event-details" element={<EventPost />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset-password-success" element={<ResetSuccessScreen />} />
          <Route path="/physician-details" element={<PhysicianDetails />} />
          <Route path="/profile-details" element={<PhysicianNewDetails />} />
          <Route path="/group-details" element={<CommunityGroup />} />
        </Routes>
      </Layout>
    </>
  );
};
export default App;
